
import * as images from "img";
import rnenvCodeData from "./rnenvCodeData";
import BlogModel from "../../../../models/Blog/BlogModel";

const rnEnvId = 0;

export const rnenvBlogData: BlogModel = {
    id: 'rn-env',
    link: '/blog/rn-env',
    title: 'React Native: Multiple Environments Setup Without Third Party Libraries',
    intro: `<p>Creating different builds with different configurations is a simple enough task and we should not have the need to use a third party library.<br/><br/> In this tutorial we are going to create three builds (Schemes/Flavors): <strong>development,</strong> <strong>staging</strong> and <strong>production.</strong></p>`,
    content: [
        {
            key: `rn-env${rnEnvId}`,
            type: "header",
            inner: "Creating a new App"
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "text",
            inner: "We start a brand new project"
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "code",
            code_lang: "bash",
            inner: `npx react-native init TestProject`
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "text",
            inner: "We'll do a package install and pod install"
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "code",
            code_lang: "bash",
            inner: `npm i`
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "code",
            code_lang: "bash",
            inner: `cd ios && pod install`
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "header",
            inner: "Setting the enviroment"
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "text",
            inner: "Because we are not using a third party library we'll need to create our own NativeModules for each platform, Android and iOS."
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "text",
            inner: "Inside the root folder of the project, we'll create the file <strong>environment.ts</strong> to set all the variables for the different builds."
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "code",
            code_lang: "typescript",
            inner: rnenvCodeData.enviroment_ts,
            code_title: "environment.ts"
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "header",
            inner: "Setup for Android"
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "text",
            inner: "We'll start by creating our own NativeModule in Java. Let's go to android > app > src > main > java > com > testproject > app and we'll create two files <strong>RNConfigPackage.java</strong> and <strong>RNConfigModule.java</strong>"
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "code",
            code_lang: "java",
            inner: rnenvCodeData.rnconfigmodule_java,
            code_title: "android/app/src/main/java/com/testproject/app/RNConfigModule.java",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "code",
            code_lang: "java",
            inner: rnenvCodeData.rnconfigpackage_java,
            code_title: "android/app/src/main/java/com/testproject/app/RNConfigPackage.java"
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "text",
            inner: "Now we'll add the package to our <strong>MainApplication.kt</strong>"
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "code",
            code_lang: "kotlin",
            inner: rnenvCodeData.mainApplication_kt,
            code_title: "android/app/src/main/java/com/testproject/app/MainApplication.kt",
            code_highlights: "22",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "text",
            inner: "Now we'll set the environment variable inside our <strong>android/app/build.gradle</strong>."
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "code",
            code_lang: "groovy",
            inner: rnenvCodeData.build_gradle,
            code_title: "android/app/build.gradle",
            code_highlights: "5-9, 22"
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "text",
            inner: "In this case we set it as a system env variable set in our <strong>~/.zprofile</strong> but if you like you can hard coded as dev, prod or staging depending on the desired build."
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "code",
            code_lang: "bash",
            inner: rnenvCodeData.zprofile,
            code_title: "~/.zprofile",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "header",
            inner: "Setup for iOS"
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "text",
            inner: "We'll start by opening the project in XCode. Open the TestProject.xcworkspace. Inside the TestProject folder we'll create a two files <strong>RNConfig.m</strong> and <strong>RNConfig.h</strong>"
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "image",
            inner: images.xcode1,
            image_alt: "Create new file in project",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "text",
            inner: "First we'll create de <strong>RNConfig.h</strong> file"
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "image",
            inner: images.xcode2,
            image_alt: "Create header file",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "image",
            inner: images.xcode3,
            image_alt: "Select target for file",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "code",
            code_lang: "objective-c",
            inner: rnenvCodeData.rnconfig_h,
            code_title: "ios/RNConfig.h"
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "text",
            inner: "Now we'll make the <strong>RNConfig.m</strong> file."
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "image",
            inner: images.xcode4,
            image_alt: "Create objective-c file",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "image",
            inner: images.xcode5,
            image_alt: "Selec target for file",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "code",
            code_lang: "objective-c",
            inner: rnenvCodeData.rnconfig_m,
            code_title: "ios/RNConfig.m"
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "text",
            inner: "Now we'll create the different configurations we need. In this case we'll create development, staging and production configurations"
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "image",
            inner: images.xcode6,
            image_alt: "Create new configuration",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "text",
            inner: "We'll duplicate the Debug configuration for development and staging, and the Release configuration for production"
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "image",
            inner: images.xcode7,
            image_alt: "Duplicate configuration",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "image",
            inner: images.xcode8,
            image_alt: "Result for created configurations",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "text",
            inner: "Now we'll create different schemes for each configuration we made."
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "image",
            inner: images.xcode9,
            image_alt: "Create new scheme",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "image",
            inner: images.xcode10,
            image_alt: "Name new scheme",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "text",
            inner: "Now we'll edit all three schemes to set the desire build configuration to each one. Make sure to set it for all different stages <strong>Build, Run, Test, Profile, Analyze and Archive.</strong>"
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "image",
            inner: images.xcode11,
            image_alt: "Edit scheme",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "image",
            inner: images.xcode11_1,
            image_alt: "Edit scheme production",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "image",
            inner: images.xcode11_2,
            image_alt: "Select new scheme to edit",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "image",
            inner: images.xcode11_3,
            image_alt: "Edit scheme development",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "image",
            inner: images.xcode11_4,
            image_alt: "Edit scheme staging",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "text",
            inner: "We need to set the <strong>RNConfig.m</strong> file in Compile Sources in Build Phases. Sometimes is set by default and sometimes isn't. Make sure its set in all the different Schemes"
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "image",
            inner: images.xcode12,
            image_alt: "Set RNConfig.m in compile resourcesimage",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "text",
            inner: "Now we'll add our different configurations to our Podfile."
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "code",
            code_lang: "ruby",
            inner: rnenvCodeData.podfile,
            code_title: "ios/Podfile",
            code_highlights: "17-20, 25",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "text",
            inner: "Finally we need to set the variables used in our <strong>environment.ts</strong> file (dev, staging, prod) in each configuration."
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "text",
            inner: "In the Build Settings of our target in images.xcode use the search bar to look for <strong><i>GCC_PREPROCESSOR_DEFINITIONS</i></strong> and set the corresponding variables to each scheme. In the Development scheme we should have the variable <strong><i>DEV=1</i></strong>. In Staging we should have <strong><i>STAGING=1</i></strong> and in Production <strong><i>PROD=1</i></strong>"
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "image",
            inner: images.xcode14,
            image_alt: "Set env variables in xcode",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "image",
            inner: images.xcode15,
            image_alt: "Add new variable",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "image",
            inner: images.xcode16,
            image_alt: "Add new variable",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "image",
            inner: images.xcode17,
            image_alt: "Show result of all variables created",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "header",
            inner: "Let's try it out"
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "text",
            inner: "We are done! Let's test it out. We'll modify our <strong>App.tsx</strong> to set the name of the environment we are using."
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "code",
            code_lang: "tsx",
            inner: rnenvCodeData.app_tsx,
            code_title: "App.tsx",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "image",
            inner: images.iphone_dev,
            image_alt: "Show Iphone example of development environment",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "image",
            inner: images.iphone_stag,
            image_alt: "Show Iphone example of development staging",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "image",
            inner: images.iphone_prod,
            image_alt: "Show Iphone example of development production",
        },
        {
            key: `rn-env${rnEnvId}`,
            type: "text",
            inner: "And that's all. Happy Coding!"
        },
    ]
}