export const me = require("./me.jpeg");

//blogs
export const xcode1 = require("./blogs/rn-env/xcode1.png");
export const xcode2 = require("./blogs/rn-env/xcode2.png");
export const xcode3 = require("./blogs/rn-env/xcode3.png");
export const xcode4 = require("./blogs/rn-env/xcode4.png");
export const xcode5 = require("./blogs/rn-env/xcode5.png");
export const xcode6 = require("./blogs/rn-env/xcode6.png");
export const xcode7 = require("./blogs/rn-env/xcode7.png");
export const xcode8 = require("./blogs/rn-env/xcode8.png");
export const xcode9 = require("./blogs/rn-env/xcode9.png");
export const xcode10 = require("./blogs/rn-env/xcode10.png");
export const xcode11 = require("./blogs/rn-env/xcode11.png");
export const xcode11_1 = require("./blogs/rn-env/xcode11_1.png");
export const xcode11_2 = require("./blogs/rn-env/xcode11_2.png");
export const xcode11_3 = require("./blogs/rn-env/xcode11_3.png");
export const xcode11_4 = require("./blogs/rn-env/xcode11_4.png");
export const xcode12 = require("./blogs/rn-env/xcode12.png");
export const xcode13 = require("./blogs/rn-env/xcode13.png");
export const xcode14 = require("./blogs/rn-env/xcode14.png");
export const xcode15 = require("./blogs/rn-env/xcode15.png");
export const xcode16 = require("./blogs/rn-env/xcode16.png");
export const xcode17 = require("./blogs/rn-env/xcode17.png");
export const iphone_dev = require("./blogs/rn-env/iphone_dev.png");
export const iphone_prod = require("./blogs/rn-env/iphone_prod.png");
export const iphone_stag = require("./blogs/rn-env/iphone_stag.png");
