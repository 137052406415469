import React from 'react';

import {
    Pdf,
} from 'icons';

export default function CvButton(){
    return(
        <a 
            className='cvButton'
            href={require("../../assets/files/cv.pdf")} download="genesis-bell-cv"
        >
            <Pdf/>
            <p>Download Resume</p>
        </a>
    )
}