import React from 'react';

//Components
import CvButton from '../../Common/CvButton';

//Assets
import './ResumePageStyle.css';
import {
    Github,
    Linkedin,
    Twitter,
} from 'icons';

import { resumeData, skills } from './resumeData';


export default function ResumePage(){

    return(
        <main id='resume'>
            <h1>Resume</h1>
            <hr/>
            <h3>email</h3>
            <p className='email'>genesisbell14@gmail.com</p>
            <h3>Network</h3>
            <div className='socials'>
                <a href='https://github.com/genesisbell' target="_blank" rel="noreferrer">
                    <Github/>
                </a>
                <a href='https://www.linkedin.com/in/genesisbell/' target="_blank" rel="noreferrer">
                    <Linkedin/>
                </a>
                <a href='https://twitter.com/genesisbbell' target="_blank" rel="noreferrer">
                    <Twitter/>
                </a>
            </div>
            <CvButton/>
            <div id="resume-content">
                {
                    resumeData.map(d => (
                    <section id={d.id} key={d.id}>
                        <h2>{d.title}</h2>
                        {
                            d.content.map(dc => (
                                <div className='content' key={dc.id}>
                                    <div className='content-title'>
                                        <p>{dc.title}<span>{dc.subtitle}</span></p>
                                    </div>
                                    {
                                        dc.contentList.map((cl, index) => (
                                            <div className='content-body' key={index}>
                                                <ul><li>{cl}</li></ul>
                                            </div>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </section>
                    ))
                }
                <section id="skills">
                    <h2>Skills</h2>
                    <div className='content'>
                        <div className='content-body'>
                            {
                                skills.map((s, index) => (
                                    <p key={index}>{s}</p>
                                ))
                            }
                        </div>
                    </div>
                </section>

                <section >
                    <h2>Additional Skills</h2>
                    <div className='content'>
                        <div className='content-body'>
                            <p>English - fluent</p>
                            <p>Spanish - native speaker</p>
                        </div>
                    </div>
                </section>

                <section >
                    <h2>Hobbies</h2>
                    <div className='content'>
                        <div className='content-body'>
                            <p>Swimming</p>
                            <p>Hiking</p>
                            <p>Anything nature</p>
                        </div>
                    </div>
                </section>

            </div>

        </main>
    )
}