const code = {
  enviroment_ts:`import { NativeModules } from "react-native";

export const ENV = {
  dev:{
    name: "dev",
    description: "This the development enviroment",
  },
  stag:{
    name: "stag",
    description: "This the staging enviroment",
  },
  prod:{
    name: "prod",
    description: "This the production enviroment",
  },
}

const getEnvVars = () => {
  const env = NativeModules.RNConfig.getConstants().env;
  switch(env){
    case "prod":
      return ENV.prod
    case "staging":
      return ENV.stag
    case "dev":
      return ENV.dev
    default:
      return ENV.dev
  }
};

export default getEnvVars;

`,
rnconfigmodule_java: `package com.velovita.app;
import com.facebook.react.bridge.NativeModule;
import com.facebook.react.bridge.ReactApplicationContext;
import com.facebook.react.bridge.ReactContext;
import com.facebook.react.bridge.ReactContextBaseJavaModule;
import com.facebook.react.bridge.ReactMethod;
import java.util.Map;
import java.util.HashMap;

public class RNConfigModule extends ReactContextBaseJavaModule {
 RNConfigModule(ReactApplicationContext context) {
     super(context);
 }

  @Override
  public String getName() {
      return "RNConfig";
  }

  @Override
  public Map<String, Object> getConstants() {
      final Map<String, Object> contansts = new HashMap<>();
      contansts.put("env", BuildConfig.RN_CONFIG_ENV);
      return contansts;
  }
}
`,
rnconfigpackage_java: `package com.velovita.app;
import com.facebook.react.ReactPackage;
import com.facebook.react.bridge.NativeModule;
import com.facebook.react.bridge.ReactApplicationContext;
import com.facebook.react.uimanager.ViewManager;

import java.util.ArrayList;
import java.util.Collections;
import java.util.List;

public class RNConfigPackage implements ReactPackage {

 @Override
 public List<ViewManager> createViewManagers(ReactApplicationContext reactContext) {
     return Collections.emptyList();
 }

 @Override
 public List<NativeModule> createNativeModules(
         ReactApplicationContext reactContext) {
     List<NativeModule> modules = new ArrayList<>();

     modules.add(new RNConfigModule(reactContext));

     return modules;
 }
}`,
  mainApplication_kt: `package com.testproject

import android.app.Application
import com.facebook.react.PackageList
import com.facebook.react.ReactApplication
import com.facebook.react.ReactHost
import com.facebook.react.ReactNativeHost
import com.facebook.react.ReactPackage
import com.facebook.react.defaults.DefaultNewArchitectureEntryPoint.load
import com.facebook.react.defaults.DefaultReactHost.getDefaultReactHost
import com.facebook.react.defaults.DefaultReactNativeHost
import com.facebook.soloader.SoLoader

class MainApplication : Application(), ReactApplication {

  override val reactNativeHost: ReactNativeHost =
      object : DefaultReactNativeHost(this) {
        override fun getPackages(): List<ReactPackage> =
            PackageList(this).packages.apply {
              // Packages that cannot be autolinked yet can be added manually here, for example:
              // add(MyReactNativePackage())
              add(RNConfigPackage())
            }

        override fun getJSMainModuleName(): String = "index"

        override fun getUseDeveloperSupport(): Boolean = BuildConfig.DEBUG

        override val isNewArchEnabled: Boolean = BuildConfig.IS_NEW_ARCHITECTURE_ENABLED
        override val isHermesEnabled: Boolean = BuildConfig.IS_HERMES_ENABLED
      }

  override val reactHost: ReactHost
    get() = getDefaultReactHost(applicationContext, reactNativeHost)

  override fun onCreate() {
    super.onCreate()
    SoLoader.init(this, false)
    if (BuildConfig.IS_NEW_ARCHITECTURE_ENABLED) {
      // If you opted-in for the New Architecture, we load the native entry point for this app.
      load()
    }
  }
}
`,
  build_gradle: `.
.
.
android {
def envName = System.getenv("TEST_PROJECT_ANDROID_ENV")

if(envName == null){
  throw new GradleException("Wrong usage. Make sure to set the TEST_PROJECT_ANDROID_ENV environment variable before proceeding.")
}

ndkVersion rootProject.ext.ndkVersion
buildToolsVersion rootProject.ext.buildToolsVersion
compileSdk rootProject.ext.compileSdkVersion

namespace "com.testproject"
defaultConfig {
  applicationId "com.testproject"
  minSdkVersion rootProject.ext.minSdkVersion
  targetSdkVersion rootProject.ext.targetSdkVersion
  versionCode 1
  versionName "1.0"
  buildConfigField "String", "RN_CONFIG_ENV", "\${envName}"
}
.
.
.`,
  zprofile: `export TEST_PROJECT_ANDROID_ENV=prod`,
  rnconfig_h: `
#import <Foundation/Foundation.h>
#import <React/RCTBridgeModule.h>

NS_ASSUME_NONNULL_BEGIN

@interface RNConfig : NSObject <RCTBridgeModule>

@end

NS_ASSUME_NONNULL_END
`,
  rnconfig_m: `
#import "RNConfig.h"

@implementation RNConfig

RCT_EXPORT_MODULE();

- (NSDictionary *)constantsToExport
{
#if DEV
  NSString *env=@"dev";
#elif STAGING
  NSString *env=@"staging";
#else
  NSString *env=@"prod";
#endif
  return @{ @"env":env};
}
+ (BOOL)requiresMainQueueSetup
{
  return YES;
}
@end
`,
podfile: `# Resolve react_native_pods.rb with node to allow for hoisting
require Pod::Executable.execute_command('node', ['-p',
  'require.resolve(
    "react-native/scripts/react_native_pods.rb",
    {paths: [process.argv[1]]},
  )', __dir__]).strip

platform :ios, min_ios_version_supported
prepare_react_native_project!

linkage = ENV['USE_FRAMEWORKS']
if linkage != nil
  Pod::UI.puts "Configuring Pod with #{linkage}ally linked Frameworks".green
  use_frameworks! :linkage => linkage.to_sym
end

project 'TestProject',{
  'Development' => :debug,
  'Staging' => :debug,
  'Production' => :release,
}

target 'TestProject' do
  config = use_native_modules!
  debugs =['Development','Staging']

  use_react_native!(
    :path => config[:reactNativePath],
    # An absolute path to your application root.
    :app_path => "#{Pod::Config.instance.installation_root}/.."
  )

  target 'TestProjectTests' do
    inherit! :complete
    # Pods for testing
  end

  post_install do |installer|
    # https://github.com/facebook/react-native/blob/main/packages/react-native/scripts/react_native_pods.rb#L197-L202
    react_native_post_install(
      installer,
      config[:reactNativePath],
      :mac_catalyst_enabled => false,
      # :ccache_enabled => true
    )
  end
end
`,
app_tsx: `import React from 'react';
import {
  SafeAreaView,
  Text,
} from 'react-native';

import getEnvVars from './enviroment';

function App(): React.JSX.Element {

  return (
    <SafeAreaView>
      <Text>{getEnvVars().name}</Text>
      <Text>{getEnvVars().description}</Text>
    </SafeAreaView>
  );
}

export default App;
`,
} 

export default code;
