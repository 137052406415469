import React from 'react';

//Components
import CvButton from '../../Common/CvButton';

//Assets
import './AboutMePageStyle.css';
import { me } from 'img';

export default function AboutMePage(){
    
    return(
        <main id='aboutMe'>
            <div className='title'>
                <h1>Genesis Bell</h1>
                <h3>Developer</h3>
            </div>
            <div className='info'>
                <img src={me} alt="Genesis Bell"/>
                <div className='info2'>
                    <p>
                        I'm a software engineer who resides in Mexico. 
                        I work in web and mobile development using JavaScript, NodeJs, Python, React and more.
                    </p>
                    <CvButton/>

                </div>
            </div>
        </main>
    )
}
