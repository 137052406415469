export const resumeData = [
    {
        id: 'work-experience',
        title: 'Work Experience',
        content: [
            {
                id: 1,
                title: 'Mobile Developer',
                subtitle: 'Gara Group, Remote / March 2022 - Current',
                contentList: [
                    "Work with others to help maintain and improve the company’s mobile application made with React Native."
                ]
            },
            {
                id: 2,
                title: 'FullStack Web and Mobile Developer',
                subtitle: 'GlobalMet, Hermosillo, Sonora / May 2021 - March 2022',
                contentList: [
                    "Developed a weather mobile application with backend made with Django and frontend made with React Native.",
                    "Worked with others to help maintain and improve the company’s mobile application made with React Native."
                ]
            },
            {
                id: 3,
                title: 'FullStack Web Developer',
                subtitle: 'BluSoft Development, Hermosillo, Sonora / August 2020 - May 2021',
                contentList: [
                    "Worked with React Js and Django making web and mobile applications for different clients.",
                ]
            },
            {
                id: 4,
                title: 'Accountant',
                subtitle: 'Grupo Molina, Hermosillo, Sonora / May 2018 - December 2019',
                contentList: [
                    "Prepare, examine, and analyze accounting records, financial statements, or other financial reports",
                    "Taxes payment",
                    "Verify accuracy of billing data and revise any errors",
                    "Operate accounting software to record, store, and analyze information",
                ]
            },
            {
                id: 5,
                title: 'Bookkeeper',
                subtitle: 'Distribuciones Dana, Hermosillo, Sonora / November 2016 - December 2017',
                contentList: [
                    "In charge of expenses in more than thirty branch offices and subordinate manager.",
                ]
            },
        ]
    },
    {
        id: 'education',
        title: 'Education',
        content: [
            {
                id: 1,
                title: 'Software Engineering',
                subtitle: 'Universidad de Sonora / August 2016 - December 2020',
                contentList: []
            },
            {
                id: 2,
                title: 'Accounting',
                subtitle: 'Universidad Estatal de Sonora / August 2014 -  May 2019',
                contentList: []
            },
            {
                id: 3,
                title: 'Relevant Coursework',
                subtitle: '',
                contentList: [
                    'Hackaton Rasperry Pi 2018',
                    'Java School at nearsoft',
                    'Web development bootcamp 2020',
                    'Life',
                ]
            },
        ]
    },
];

export const skills = [
    '✔ JavaScript',
    '✔ Html',
    '✔ CSS',
    '✔ React JS',
    '✔ React Native',
    '✔ PostgreSql',
    '✔ MongoDB',
    '✔ Node JS',
    '✔ Python',
    '✔ Django'
]
