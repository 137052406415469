import React from 'react';
import { Link } from 'react-router-dom';
import { blogs } from './blogData';


import './BlogStyles.css';
import { xcode1 } from 'img';

export default function BlogPage() {
    return (
        <main>
            <h1>Blog</h1>
            <hr />
            {
                blogs.map(blog => (
                    <Link className='blog_link' to={blog.link}>
                        <img src={xcode1} alt="React Native Environement Intro" />
                        <div>
                            <h3>{blog.title}</h3>
                            <div className='blog_subtitle' dangerouslySetInnerHTML={{ __html: blog.intro }}></div>
                        </div>
                    </Link>
                ))
            }

        </main>
    )
}