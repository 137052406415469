import React from 'react';
import {
    Link,
    useLocation,
} from 'react-router-dom';

//Components
import ThemeSelection from '../ThemeSelection';

//Assets
import './NavBarStyles.css';
import {
    Arrow,
    Blog,
    Portfolio,
    Person,
    Resume,
} from 'icons';

export default function NavBar(){

    const location = useLocation();
    const menuItems = [
        {
            id: 1,
            text: 'About Me',
            icon: <Person className='navbar-icon'/>,
            link: '/',
        },
        {
            id: 2,
            text: 'Resume',
            icon: <Resume className='navbar-icon'/>,
            link: '/resume',
        },
        {
            id: 3,
            text: 'Portfolio',
            icon: <Portfolio className='navbar-icon'/>,
            link: '/portfolio',
        },
        {
            id: 4,
            text: 'Blog',
            icon: <Blog className='navbar-icon'/>,
            link: '/blog',
        },
    ];

    return(
        <nav className='navbar'>
            <ul className='navbar-nav'>
                <li className='logo' >
                    <Link to={'/'} className='nav-link'>
                        <span className='link-text'>Menu</span>
                        <Arrow className='navbar-icon'/>
                    </Link>
                </li>
                {
                    menuItems.map(item => {

                        const style = item.link === location.pathname ? 'nav-link-active' : '';

                        return(
                            <li className='nav-item' key={item.id}>
                                <Link to={item.link} className={`nav-link ${style}`}>
                                    {item.icon}
                                    <span className='link-text'>{item.text}</span> 
                                </Link>
                            </li>
                        )
                    })
                }
                <ThemeSelection/>
            </ul>
        </nav>
    )
}
