import React from "react";
import { ContentData } from "../../../models/Blog/BlogModel"
import { CopyBlock, solarizedDark } from "react-code-blocks";

interface BlogInnerProps {
    content: ContentData;
}

export default function BlogInner(props: BlogInnerProps){

    /** @Variables */
    const {
        content,
    } = props;
    /** */

    if(content.type === "header"){
        return(
            <h2>{content.inner}</h2>
        )
    }

    if(content.type === "text"){
        return (
            <div className="paragraph">
                <div dangerouslySetInnerHTML={{__html: content.inner}}/>
            </div>
        )
    }

    if(content.type === "code"){
        return (
            <div className="paragraph">
                {
                    content.code_title && (
                        <p className="code_title">{content.code_title}</p>
                    )
                }
                <CopyBlock
                    text={content.inner}
                    theme={solarizedDark}
                    language={content.code_lang || ""}
                    codeBlock
                    highlight={content.code_highlights || ""}
                />
            </div>
        )
    }

    if(content.type === "image"){
        return (
            <div className="paragraph">
                <img src={content.inner} alt={content.image_alt || ""} className="blog_img"/>
            </div>
        )
    }

    return <div></div>;

}