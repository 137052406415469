import React from 'react';

import BlogModel from '../../../models/Blog/BlogModel';
import BlogInner from './BlogInner';

interface BlogItemProps {
    blog: BlogModel;
}

export default function BlogItem(props: BlogItemProps) {

    /** @Variables */
    const {
        blog,
    } = props;
    /** */

    return (
        <main>
            <h1>{blog.title}</h1>
            <hr />
            <div id="blog_main">
                <div dangerouslySetInnerHTML={{ __html: blog.intro }} />
                {
                    blog.content.map(content => (
                        <BlogInner key={content.key} content={content} />
                    ))
                }
            </div>
        </main>
    )
}