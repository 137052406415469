import React from 'react';

//Assets
import {
    Moon,
    Sun,
    Sunglasses,
} from 'icons';

export default function ThemeSelection(){

    const themeMap = new Map<string, string>([
        ['dark', 'light'],
        ['light', 'solar'],
        ['solar', 'dark'],
    ]);

    //Gets first value of map aka dark
    const defaultTheme = themeMap.keys().next().value;

    //Get theme from localStorage but if its null, set localStorage theme and gets the defaultTheme
    let theme = localStorage.getItem('theme') || (localStorage.setItem('theme', defaultTheme), defaultTheme); 

    const bodyClass = document.body.classList;
    bodyClass.add(theme);

    function handleThemeSelection(){
        let current = localStorage.getItem('theme') || defaultTheme;
        const next = themeMap.get(current) || defaultTheme;

        bodyClass.replace(current, next);
        localStorage.setItem('theme', next);
    }

    return(
        <li className='nav-item nav-link' onClick={handleThemeSelection}>
            <Sun id='darkIcon' className='theme-icon navbar-icon'/>
            <Sunglasses id='lightIcon' className='theme-icon navbar-icon'/>
            <Moon id='solarIcon' className='theme-icon navbar-icon'/>
            <span className='link-text'>Theme</span> 
        </li>
    );
}