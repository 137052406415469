import React from 'react';

export default function PortfolioPage(){
    return(
        <main>
            <h1>Portfolio</h1>
            <hr/>
            <p>Working on it...</p>
        </main>
    )
}