import React from 'react';
import './index.css';

//Components
import AppRouter from './Components/Navigation/AppRouter';

function App() {
  return (
      <AppRouter/>
  );
}

export default App;
