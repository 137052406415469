import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

//Components
import NavBar from "../Common/NavBar/NavBar";
import Footer from "../Common/Footer/Footer";

//Pages
import AboutMePage from "../Pages/AboutMe/AboutMePage";
import BlogPage from "../Pages/Blog/BlogPage";
import PortfolioPage from "../Pages/Portfolio/PortfolioPage";
import ResumePage from "../Pages/Resume/ResumePage";
import BlogItem from "../Pages/Blog/BlogItem";
import { blogs } from "../Pages/Blog/blogData";

export default function AppRouter(){
    return(
        <BrowserRouter>
            <NavBar/>
            <Routes>
                <Route path="/" element={<AboutMePage/>}/>
                <Route path="/resume" element={<ResumePage/>}/>
                <Route path="/blog" element={<BlogPage/>}/>
                {
                    blogs.map(blog => (
                        <Route path={blog.link} element={<BlogItem blog={blog}/>}/>
                    ))
                }
                <Route path="/portfolio" element={<PortfolioPage/>}/>
            </Routes>
            <Footer/>
        </BrowserRouter>
    );
}